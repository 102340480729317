<template>
  <HeaderBlock />
  <div class="box">
      <AsideBlock />
      <main class="main">
          <div class="main-title">{{ t('deposit') }}</div>
          <div class="main-methods">
              <div class="main-methods-top">
                  <div class="main-methods-icon">
                      <img src="@/assets/images/methods.png" alt="">
                  </div>
                  <div class="main-methods-title">{{ t('home.methods') }}</div>
              </div>
              <div class="main-tabs">
                  <div class="main-tabs-navigation">
                      <div class="nav main-tabs-nav" id="main-tab" role="tablist" aria-orientation="vertical">
                          <button class="nav-link main-tab" id="main-tab-btc-tab" data-bs-toggle="pill" data-bs-target="#main-tab-btc" type="button" role="tab" aria-controls="main-tab-btc" aria-selected="true" @click="hideActive">
                              <div class="main-tab-icon">
                                  <img src="@/assets/images/btc.png" alt="">
                              </div>
                              <div class="main-tab-name">{{ t('home.btc') }}</div>
                              <div class="main-tab-time">{{ t('home.time') }}</div>
                          </button>
                          <button class="nav-link main-tab" id="main-tab-usdt-tab" data-bs-toggle="pill" data-bs-target="#main-tab-usdt" type="button" role="tab" aria-controls="main-tab-btc" aria-selected="true" @click="hideActive">
                              <div class="main-tab-icon">
                                  <img src="@/assets/images/eth.webp" alt="">
                              </div>
                              <div class="main-tab-name">Etherim wallet</div>
                              <div class="main-tab-time">{{ t('home.time') }}</div>
                          </button>
                          <button class="nav-link main-tab" id="main-tab-usdt2-tab" data-bs-toggle="pill" data-bs-target="#main-tab-usdt2" type="button" role="tab" aria-controls="main-tab-btc" aria-selected="true" @click="hideActive">
                              <div class="main-tab-icon">
                                  <img src="@/assets/images/usdt2.png" alt="">
                              </div>
                              <div class="main-tab-name">{{ t('home.trc20') }}</div>
                              <div class="main-tab-time">{{ t('home.time') }}</div>
                          </button>
                          <button class="nav-link main-tab" id="main-tab-card-tab" data-bs-toggle="pill" data-bs-target="#main-tab-card" type="button" role="tab" aria-controls="main-tab-btc" aria-selected="true" @click="hideActive">
                              <div class="main-tab-icon">
                                  <img src="@/assets/images/card.png" alt="">
                              </div>
                              <div class="main-tab-name">{{ t('home.credit') }}</div>
                              <div class="main-tab-time">{{ t('home.time') }}</div>
                          </button>
                          <button class="nav-link main-tab" id="main-tab-other-tab" data-bs-toggle="pill" data-bs-target="#main-tab-other" type="button" role="tab" aria-controls="main-tab-btc" aria-selected="true" @click="hideActive">
                              <div class="main-tab-icon">
                                  <img src="@/assets/images/menu.png" alt="">
                              </div>
                              <div class="main-tab-name">{{ t('home.other') }}</div>
                              <div class="main-tab-time">{{ t('home.time') }}</div>
                          </button>
                      </div>
                  </div>
                  <div class="main-tabs-content tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="main-tab-first" role="tabpanel" aria-labelledby="main-tab-first-tab" tabindex="0" v-if="active">
                            <div class="main-logos">
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/01.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/02.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/03.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/04.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/05.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/06.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/07.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/08.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/09.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/10.png" alt="">
                              </div>
                              <div class="main-logo">
                                  <img src="@/assets/images/btc/11.png" alt="">
                              </div>
                          </div>
                        </div>
                      <div class="tab-pane fade" id="main-tab-btc" role="tabpanel" aria-labelledby="main-tab-btc-tab" tabindex="0">
                        <div class="main-item">
                            <div class="main-item-title">QR Code</div>
                            <div class="main-item-qr">
                                <img src="@/assets/images/qr-code.png" alt="">
                            </div>
                            <div class="main-item-title">Deposit</div>
                            <div class="main-item-wallet">
                                <div class="main-item-wallet-address">1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J</div>
                                <button class="main-item-wallet-copy" @click="copy('1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J')">Copy</button>
                            </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="main-tab-usdt" role="tabpanel" aria-labelledby="main-tab-usdt-tab" tabindex="0">
                        <div class="main-item">
                            <div class="main-item-title">QR Code</div>
                            <div class="main-item-qr">
                                <img src="@/assets/images/qr-code.png" alt="">
                            </div>
                            <div class="main-item-title">Deposit</div>
                            <div class="main-item-wallet">
                                <div class="main-item-wallet-address">1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J</div>
                                <button class="main-item-wallet-copy" @click="copy('1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J')">Copy</button>
                            </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="main-tab-usdt2" role="tabpanel" aria-labelledby="main-tab-usdt2-tab" tabindex="0">
                        <div class="main-item">
                            <div class="main-item-title">QR Code</div>
                            <div class="main-item-qr">
                                <img src="@/assets/images/qr-code.png" alt="">
                            </div>
                            <div class="main-item-title">Deposit</div>
                            <div class="main-item-wallet">
                                <div class="main-item-wallet-address">1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J</div>
                                <button class="main-item-wallet-copy" @click="copy('1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J')">Copy</button>
                            </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="main-tab-card" role="tabpanel" aria-labelledby="main-tab-card-tab" tabindex="0">
                        <div class="main-item">
                            <div class="main-item-title">QR Code</div>
                            <div class="main-item-qr">
                                <img src="@/assets/images/qr-code.png" alt="">
                            </div>
                            <div class="main-item-title">Deposit</div>
                            <div class="main-item-wallet">
                                <div class="main-item-wallet-address">1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J</div>
                                <button class="main-item-wallet-copy" @click="copy('1LqFq6sP3WiyRSrkCRHmvyqWjxZNqWtL2J')">Copy</button>
                            </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="main-tab-other" role="tabpanel" aria-labelledby="main-tab-other-tab" tabindex="0">
                        <PaymentCard />
                      </div>
                  </div>
              </div>
          </div>
          <div class="main-deposits">
              <div class="main-deposits-title">{{ t('home.last') }}</div>
              <div class="table-responsive">
                  <table class="main-cabinet">
                      <thead data-v-7e310de7="">
                      <tr class="fs">
                          <th class="nomobile fs">{{ t('home.table1') }}</th>
                          <th class="fs">{{ t('home.table2') }}</th>
                          <th class="fs">{{ t('home.table3') }}</th>
                          <th class="fs">{{ t('home.table4') }}</th>
                          <th class="fs">{{ t('home.table5') }}</th>
                      </tr>
                      </thead>
                      <tbody data-v-7e310de7=""></tbody>
                  </table>
              </div>
          </div>
          <div class="main-payments">
              <div class="main-payment">
                  <img src="@/assets/images/visa.png" alt="">
              </div>
              <div class="main-payment">
                  <img src="@/assets/images/master-card.png" alt="">
              </div>
              <div class="main-payment">
                  <img src="@/assets/images/stripe.png" alt="">
              </div>
              <div class="main-payment">
                  <img src="@/assets/images/pcidss.png" alt="">
              </div>
          </div>
      </main>
  </div>
</template>

<script setup>
import HeaderBlock from '@/components/HeaderBlock.vue'
import AsideBlock from '@/components/AsideBlock.vue'
import PaymentCard from '@/components/PaymentCard.vue';
import { useI18n } from 'vue-i18n'
import { ref } from 'vue';

const active = ref(true);
const hideActive = () => {
    active.value = false
}

const copy = (text) => {
    navigator.clipboard.writeText(text)
}
const { t } = useI18n()
</script>

<style lang="scss" scoped>
    .main-item{
        &-title{
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
            color: #fff;
        }
        &-qr{
            background: #fff;
            padding: 20px;
            border-radius: 5px;
            display: inline-block;
            margin-bottom: 20px;
            img{
                width: 200px;
            }
        }
        &-wallet{
            display: flex;
            align-items: center;
            gap: 20px;
            &-address{
                background: #353c4e;
                width: 100%;
                height: 50px;
                line-height: 50px;
                border-radius: 50px;
                color: #fff;
                font-weight: 700;
                padding: 0 20px;
                font-size: 20px;
            }
            &-copy{
                background: linear-gradient(265.56deg, #246CF9 -0.27%, #1E68F6 -0.26%, #0047D0 98.59%);
                height: 50px;
                line-height: 50px;
                padding: 0 75px;
                border-radius: 100px;
                color: #fff;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 300;
                transition: 0.5s;
                border: 0;
            }
        }
    }
    .payment-title {
    width: 100%;
    text-align: center;
}

.form-container .field-container:first-of-type {
    grid-area: name;
}

.form-container .field-container:nth-of-type(2) {
    grid-area: number;
}

.form-container .field-container:nth-of-type(3) {
    grid-area: expiration;
}

.form-container .field-container:nth-of-type(4) {
    grid-area: security;
}

.field-container input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.field-container {
    position: relative;
}

.form-container {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto auto;
    grid-template-rows: 90px 90px 90px;
    grid-template-areas: "name name""number number""expiration security";
    max-width: 400px;
    padding: 20px;
    color: #707070;
}

label {
    padding-bottom: 5px;
    font-size: 13px;
}

input {
    margin-top: 3px;
    padding: 15px;
    font-size: 16px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
}

.ccicon {
    height: 38px;
    position: absolute;
    right: 6px;
    top: calc(50% - 17px);
    width: 60px;
}

/* CREDIT CARD IMAGE STYLING */
.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

.container {
    width: 100%;
    max-width: 400px;
    max-height: 251px;
    height: 54vw;
    padding: 20px;
}

#ccsingle {
    position: absolute;
    right: 15px;
    top: 20px;
}

#ccsingle svg {
    width: 100px;
    max-height: 60px;
}

.creditcard svg#cardfront,
.creditcard svg#cardback {
    width: 100%;
    -webkit-box-shadow: 1px 5px 6px 0px black;
    box-shadow: 1px 5px 6px 0px black;
    border-radius: 22px;
}

#generatecard{
    cursor: pointer;
    float: right;
    font-size: 12px;
    color: #fff;
    padding: 2px 4px;
    background-color: #909090;
    border-radius: 4px;
    cursor: pointer;
    float:right;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
    -webkit-transition: fill .5s;
    transition: fill .5s;
}

.creditcard .lightblue {
    fill: #03A9F4;
}

.creditcard .lightbluedark {
    fill: #0288D1;
}

.creditcard .red {
    fill: #ef5350;
}

.creditcard .reddark {
    fill: #d32f2f;
}

.creditcard .purple {
    fill: #ab47bc;
}

.creditcard .purpledark {
    fill: #7b1fa2;
}

.creditcard .cyan {
    fill: #26c6da;
}

.creditcard .cyandark {
    fill: #0097a7;
}

.creditcard .green {
    fill: #66bb6a;
}

.creditcard .greendark {
    fill: #388e3c;
}

.creditcard .lime {
    fill: #d4e157;
}

.creditcard .limedark {
    fill: #afb42b;
}

.creditcard .yellow {
    fill: #ffeb3b;
}

.creditcard .yellowdark {
    fill: #f9a825;
}

.creditcard .orange {
    fill: #ff9800;
}

.creditcard .orangedark {
    fill: #ef6c00;
}

.creditcard .grey {
    fill: #bdbdbd;
}

.creditcard .greydark {
    fill: #616161;
}

/* FRONT OF CARD */
#svgname {
    text-transform: uppercase;
}

#cardfront .st2 {
    fill: #FFFFFF;
}

#cardfront .st3 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 600;
}

#cardfront .st4 {
    font-size: 54.7817px;
}

#cardfront .st5 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
}

#cardfront .st6 {
    font-size: 33.1112px;
}

#cardfront .st7 {
    opacity: 0.6;
    fill: #FFFFFF;
}

#cardfront .st8 {
    font-size: 24px;
}

#cardfront .st9 {
    font-size: 36.5498px;
}

#cardfront .st10 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 300;
}

#cardfront .st11 {
    font-size: 16.1716px;
}

#cardfront .st12 {
    fill: #4C4C4C;
}

/* BACK OF CARD */
#cardback .st0 {
    fill: none;
    stroke: #0F0F0F;
    stroke-miterlimit: 10;
}

#cardback .st2 {
    fill: #111111;
}

#cardback .st3 {
    fill: #F2F2F2;
}

#cardback .st4 {
    fill: #D8D2DB;
}

#cardback .st5 {
    fill: #C4C4C4;
}

#cardback .st6 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
}

#cardback .st7 {
    font-size: 27px;
}

#cardback .st8 {
    opacity: 0.6;
}

#cardback .st9 {
    fill: #FFFFFF;
}

#cardback .st10 {
    font-size: 24px;
}

#cardback .st11 {
    fill: #EAEAEA;
}

#cardback .st12 {
    font-family: 'Rock Salt', cursive;
}

#cardback .st13 {
    font-size: 37.769px;
}

/* FLIP ANIMATION */
.container {
    perspective: 1000px;
}

.creditcard {
    width: 100%;
    max-width: 400px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: -webkit-transform 0.6s;
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    cursor: pointer;
}

.creditcard .front,
.creditcard .back {
    position: absolute;
    width: 100%;
    max-width: 400px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    color: #47525d;
}

.creditcard .back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.creditcard.flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
</style>