<template>
    <header class="header">
        <div class="header-left">
            <router-link to="/" class="header-logo">
                <img src="@/assets/images/logo.png" alt="">
            </router-link>
        </div>
        <div class="header-right">
            <div class="header-plate pc">
                <img src="@/assets/images/header_plate.png" alt="">
            </div>
            <div class="header-start pc">
                <router-link to="/trade" class="header-start-btn">{{ t('start') }}</router-link>
            </div>
            <div class="header-lang pc">
                <div :class="'header-lang-flag header-lang-flag-' + locale" @click="changeLangeActive"></div>
                <div :class="langActive ? 'lang-box active' : 'lang-box'">
                  <div class="lang-item" @click="changeLang('en')">
                    <div class="lang-flag lang-flag-en"></div>
                    <div class="lang-name">English</div>
                  </div>
                  <div class="lang-item" @click="changeLang('pl')">
                    <div class="lang-flag lang-flag-pl"></div>
                    <div class="lang-name">Polska</div>
                  </div>
                  <div class="lang-item" @click="changeLang('es')">
                    <div class="lang-flag lang-flag-es"></div>
                    <div class="lang-name">Espanol</div>
                  </div>
                  <div class="lang-item" @click="changeLang('it')">
                    <div class="lang-flag lang-flag-it"></div>
                    <div class="lang-name">Italiano</div>
                  </div>
                  <div class="lang-item" @click="changeLang('de')">
                    <div class="lang-flag lang-flag-de"></div>
                    <div class="lang-name">Deutsch</div>
                  </div>
                  <div class="lang-item" @click="changeLang('pt')">
                    <div class="lang-flag lang-flag-pt"></div>
                    <div class="lang-name">Portugues</div>
                  </div>
                </div>
            </div>
            <div class="header-avatar pc">
                <img src="@/assets/images/avatar.png" alt="">
            </div>
            <div class="header-settings pc" @click="logout">
                <img src="@/assets/images/logout.png" alt="">
            </div>
            <div class="menu mob">
                <input type="checkbox" id="burger-checkbox" class="burger-checkbox">
                <label class="burger" for="burger-checkbox"></label>
                <div class="menu-list">
                    <div class="aside-top">
                        <div class="aside-user">
                            <div class="aside-avatar">person</div>
                            <div class="aside-name">Hi</div>
                            <div class="aside-id"># 2440934</div>
                        </div>
                        <div class="aside-btns">
                            <a href="#" class="aside-btn">{{ $t('aside.deposit') }}</a>
                            <a href="#" class="aside-btn">{{ $t('aside.autotrader') }}</a>
                        </div>
                        <div class="aside-menu">
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/menu.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.dashboard') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/user.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.personal') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/withdraw.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.withdraw') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/verifaction.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.verifaction') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/accounts.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.accounts') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/chat.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.live_chat') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/savings.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.savings') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/trade.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.trade') }}</div>
                            </a>
                            <a href="#" class="aside-menu-item">
                                <div class="aside-menu-icon">
                                    <img src="@/assets/images/settings.png" alt="">
                                </div>
                                <div class="aside-menu-title">{{ $t('aside.settings') }}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';

const { t, locale } = useI18n()
const langActive = ref(false)
const route = useRoute()
const router = useRouter()

locale.value = route.query.lang || 'en';

const changeLangeActive = () => {
    if(langActive.value == false){
        langActive.value = true
    } else{
        langActive.value = false
    }
}

const logout = () => {
    router.push('/login')
}

const changeLang = (lang) => {
    locale.value = lang
}
</script>