import en from '@/i18n/en.json'
import pl from '@/i18n/pl.json'
import es from '@/i18n/es.json'
import it from '@/i18n/it.json'
import de from '@/i18n/de.json'
import pt from '@/i18n/pt.json'

export const defaultLocale = 'en'

export const languages = {
    en,
    pl,
    es,
    it,
    de,
    pt
}