<template>
    <HeaderBlock />
    <div class="box">
        <AsideBlock />
        <main class="main">
            <div class="personal">
                <div class="personal-title">Personal</div>
                <div class="personal-box">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="personal-item" v-if="user?.name">
                                <input type="text" placeholder="Name" v-model="user.name">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="personal-item" v-if="user?.last">
                                <input type="text" placeholder="Lastname" v-model="user.last">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="personal-item" v-if="user?.email">
                                <input type="email" placeholder="Email" v-model="user.email">
                            </div>
                        </div>
                    </div>
                    <div class="personal-bottom" v-if="user?.name">
                        <div class="row">
                            <div class="col-md-8"></div>
                            <div class="col-md-4">
                                <button class="personal-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBlock from '@/components/HeaderBlock.vue'
import AsideBlock from '@/components/AsideBlock.vue'
import { mapGetters } from 'vuex';

export default{
    name: 'TradeView',
    data() {
        return {
            user: null
        }
    },
    components: {
        HeaderBlock,
        AsideBlock
    },
    computed: {
        ...mapGetters([
            'getUser'
        ])
    },
    mounted() {
        if(this.user == null){
            this.user = this.getUser
        }
    },
}
</script>

<style lang="scss" scoped>
    .personal{
        width: 100%;
        height: 100%;
        &-title{
            text-align: center;
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 30px;
        }
        &-item{
            input{
                width: 100%;
                height: 45px;
                padding: 0 20px;
                border-radius: 5px;
                border: 1px solid #ccc;
                background: none;
                color: #fff;
            }
        }
        &-bottom{
            margin-top: 30px;
        }
        &-btn{
            width: 100%;
            line-height: 50px;
            border: 0;
            text-align: center;
            background: linear-gradient(265.56deg, #246CF9 -0.27%, #1E68F6 -0.26%, #0047D0 98.59%);
            height: 50px;
            border-radius: 100px;
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            transition: 0.5s;
        }
    }
</style>