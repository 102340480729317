<template>
    <aside :class="showSidebar ? 'aside pc' : 'aside mini pc'">
        <div class="aside-top">
            <div class="aside-user">
                <div class="aside-avatar">
                    <img src="@/assets/images/avatar.png" alt="">
                </div>
                <div class="aside-name">{{ name }} {{ last }}</div>
                <div class="aside-id">{{ email }}</div>
            </div>
            <div class="aside-btns">
                <router-link to="/" class="aside-btn">{{ $t('aside.deposit') }}</router-link>
                <a href="#" class="aside-btn">{{ $t('aside.autotrader') }}</a>
            </div>
            <div class="aside-menu">
                <router-link to="/dashboard" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/menu.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.dashboard') }}</div>
                </router-link>
                <router-link to="/personal" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/user.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.personal') }}</div>
                </router-link>
                <a href="#" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/withdraw.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.withdraw') }}</div>
                </a>
                <a href="#" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/verifaction.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.verifaction') }}</div>
                </a>
                <a href="#" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/accounts.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.accounts') }}</div>
                </a>
                <a href="#" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/savings.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.savings') }}</div>
                </a>
                <router-link to="/trade" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/trade.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.trade') }}</div>
                </router-link>
                <a href="#" class="aside-menu-item">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/settings.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.settings') }}</div>
                </a>
                <button class="aside-right aside-menu-item" @click="asideToggle">
                    <div class="aside-menu-icon">
                        <img src="@/assets/images/right.png" alt="">
                    </div>
                    <div class="aside-menu-title">{{ $t('aside.hide') }}</div>
                </button>
            </div>
        </div>
    </aside>
</template>

<script>
    import { useRoute } from 'vue-router';

    export default{
        name: 'AsideBlock',
        data() {
            return {
                showSidebar: true,
                id: '',
                name: '',
                last: '',
                email: ''
            }
        },
        mounted() {
            const route = useRoute();
            this.name = route.query.name || '';
            this.last = route.query.last || '';
            this.email = route.query.email || '';
            this.showSidebar = this.$store.state.showSidebar
            let user = {
                name: route.query.name || '',
                last: route.query.last || '',
                email: route.query.email || ''
            }
            if(this.$store.state.user == null){
                this.$store.commit('setUser', user)
            }
        },
        methods: {
            asideToggle(){
                if(this.showSidebar === true){
                    this.showSidebar = false
                    this.$store.commit('setShowSidebar', false)
                } else{
                    this.showSidebar = true
                    this.$store.commit('setShowSidebar', true)
                }
            }
        },
    }
</script>